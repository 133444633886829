<template>
    <div class="job-detail-content">
        <!-- <div
            class="contact-hr"
            v-if="
                !isEmployer &&
                jobInfo.isMyOrder &&
                jobInfo.isCooperativeJob &&
                !jobInfo.isSleeping &&
                !jobInfo.isMyJob
            "
        >
            还没有对职位了解很清晰？
            <span
                class="text-operate-btn blue"
                @click="contactRecruiter(jobInfo.recruiterUnionId)"
                >立刻勾搭职位发布者 ></span
            >
        </div> -->
        <div class="main-content">
            <div class="main-content-left">
                <div class="infos-card">
                    <div class="user-pic">
                        <avatar
                            class="pic"
                            size="sm"
                            :src="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.avatarUrl+'?size=60' : '') : jobInfo.recruiterAvatarUrl+'?size=60'"
                            :userId="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.userId : '') : jobInfo.recruiterId"
                            :enableCard="true"
                            :enableLink="true"
                        ></avatar>
                    </div>
                    <div class="user-info">
                        <p class="name">{{currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.realName : '') : jobInfo.recruiterRealName}}@{{currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.nickname : '') : jobInfo.recruiterNickname}}</p>
                        <p class="firm-info">{{currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.firmName : '') : jobInfo.recruiterFirmShortName}} {{ currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.title : '') : jobInfo.recruiterTitle}}</p>
                        <!-- <p class="time">{{jobInfo.created | createDateFilter}} 发布</p> -->
                    </div>
                    <div class="infos-right">
                        <button class="contact-btn"
                            v-if="!jobInfo.isMyJob"
                            @click="currentJobType == 3 ? contactRecruiter(jobInfo.recruiter.unionId) : contactRecruiter(jobInfo.recruiterUnionId)"
                            >联系发布者</button>
                    </div>
                </div>

                <el-tabs
                    class="job-detail-tabs"
                    v-model="jobActiveTabName"
                    @tab-click="handleJobTabClick"
                >
                    <!-- 职位 -->
                    <el-tab-pane label="职位" name="jobIntro">
                        <div class="main-content-item">
                            <h5 class="job-intro-title">职位详情：</h5>
                            <div
                                class="firm-label-list"
                                v-if="
                                    !jobInfo.isMyJob &&
                                    jobInfo.firmLabels &&
                                    jobInfo.firmLabels.length > 0
                                    && currentJobType != 3
                                "
                            >
                                <span
                                    class="firm-label-item"
                                    v-for="(label, index) in jobInfo.firmLabels"
                                    :key="index"
                                >
                                    {{ label }}
                                </span>
                            </div>
                            <pre
                                class="job-description-show"
                                v-if="!isEditJobDescription"
                                v-text="jobInfo.description"
                            ></pre>
                            <div class="job-description-edit" v-else>
                                <el-input
                                    :rows="8"
                                    type="textarea"
                                    maxlength="8000"
                                    v-model="descriptionInput"
                                    :class="
                                        descriptionError ? 'error-input' : ''
                                    "
                                    placeholder="请输入8000字内的职位描述，寻访要点，职位卖点等信息"
                                    @input.native="jobDescriptionInput"
                                    v-loading="jobDescriptionLoading"
                                ></el-input>
                                <div class="text-error" v-if="descriptionError">
                                    请填写职位要点
                                </div>
                                <el-button
                                    class="edit-btn edit-btn-confirm"
                                    @click="handleEditJobDescription"
                                    >保存</el-button
                                >
                                <el-button
                                    class="edit-btn edit-btn-cancel"
                                    @click="cancelEditJobDetail(0)"
                                    >取消</el-button
                                >
                            </div>
                            <div class="job-share-board" 
                                v-if="(jobInfo.isMyJob || jobInfo.isMyOrder || jobInfo.isProjectManagerOfJob && currentJobType != 3) && approvedStatus != 2  && jobInfo.status != 4"
                                >
                                <p class="job-share-board-msg">
                                    手里有合适的候选人？试试“<span
                                        class="text-primary"
                                        >快捷发邮件</span
                                    >”，把职位详情一键发给 ta 吧！
                                </p>
                                <button
                                    class="job-share-board-btn"
                                    @click="showShareToCandidateDialog"
                                >
                                    <font-icon
                                        class="email-icon"
                                        href="#icon-email-s"
                                    ></font-icon>
                                    分享给候选人
                                </button>
                            </div>
                        </div>
                        
                        <div class="main-content-item" v-if="![3,5].includes(currentJobType)">
                            <h5 class="job-intro-title">
                                职位分析：
                                <span
                                    class="text-operate-btn"
                                    v-if=" !( jobInfo.jobType == 2 && jobInfo.isPublic == false ) && jobInfo.acceptedHeadhuntersCount != 0 && canEdit "
                                    @click="partlyEdit(1)"
                                    >
                                    <i class="job-detail-btn-icon edit-icon el-icon-edit-outline" ></i>
                                    {{ isEditJobAnalysis ? "取消" : "编辑" }}
                                </span>
                            </h5>
                            <div
                                class="job-share-board"
                                v-if="jobInfo.isCooperativeJob && jobInfo.isMyJob" >
                                <p class="job-share-board-msg">
                                    <span class="text-primary font-bold">【本职位由我公司发布】</span >
                                    内部佣金：{{ jobInfo.internalCommissionValue }}，保证期：{{ jobInfo.internalGuaranteePeriod }}，内部退款条款按客户合同约定。
                                </p>
                            </div>
                            <pre
                                class="job-description-show"
                                v-if="!isEditJobAnalysis"
                                v-text="jobInfo.analysis"
                            ></pre>
                            <div class="job-description-edit" v-else>
                                <el-input
                                    :rows="8"
                                    type="textarea"
                                    maxlength="2000"
                                    v-model="analysisInput"
                                    placeholder="请输入2000字内的职位分析"
                                    v-loading="jobAnalysisLoading"
                                    @input.native="jobAnalysisInput"
                                >
                                </el-input>
                                <div class="text-error" v-if="analysisError">
                                    请填写职位分析
                                </div>
                                <el-button
                                    class="edit-btn edit-btn-confirm"
                                    @click="handleEditJobAnalysis"
                                    >保存</el-button
                                >
                                <el-button
                                    class="edit-btn edit-btn-cancel"
                                    @click="cancelEditJobDetail(1)"
                                    >取消</el-button
                                >
                            </div>
                        </div>

                        <div class="main-content-item">
                            <h5 class="job-intro-title">
                                工作地址：
                            </h5>
                            <pre
                                class="job-description-show"
                                v-text="jobInfo.address || '暂无'"
                            ></pre>
                        </div>
                        
                        <div class="main-content-item" v-if="![3,5].includes(currentJobType)">
                            <h5 class="job-intro-title">
                                <template
                                    v-if="jobInfo.acceptedHeadhuntersCount > 0"
                                >
                                    <span class="text-primary">{{
                                        jobInfo.acceptedHeadhuntersCount
                                    }}</span>
                                    位猎头抢单：
                                </template>
                                <template v-else> 还没有猎头抢单 </template>
                                <span
                                    class="invite-grab-btn"
                                    v-if="jobInfo.isMyJob"
                                    @click="showInviteHeadhunterDialog">
                                    邀请抢单
                                </span>
                            </h5>
                            <div class="accept-headhunters" v-if="jobInfo.acceptedHeadhuntersCount > 0" >
                                <avatar
                                    class="accepted-headhunters-avatar"
                                    size="md"
                                    :src="item.avatarUrl"
                                    :userId="item.id"
                                    :enableCard="true"
                                    :enableLink="true"
                                    v-for="item in jobInfo.acceptedHeadhunters"
                                    :key="item.id"
                                    :shareData="shareData"
                                ></avatar>
                            </div>
                            <p class=" accept-headhunters empty invite-headhunters-btn "
                                v-else-if="jobInfo.isMyJob"
                                @click="showInviteHeadhunterDialog"
                                >
                                您可以邀请您的猎头朋友来抢单推荐，赚取佣金
                            </p>
                            <p class="accept-headhunters empty" v-else-if="isHeadhunter" >
                                想挑战本职位吗，赶快抢单吧！
                            </p>
                            <p class="accept-headhunters empty" v-else>
                                还没有猎头抢单
                            </p>
                        </div>
                        <div class="main-content-item" 
                          v-else 
                          v-loading="acceptedHeadhuntersLoading">
                            <h5 class="job-intro-title">
                                <template v-if="acceptedHeadhuntersCount > 0" >
                                    <span class="text-primary">{{ acceptedHeadhuntersCount }}</span>
                                    位猎头抢单
                                </template>
                                <template v-else> 还没有猎头抢单 </template>
                                <span
                                    class="text-operate-btn"
                                    v-if="jobInfo.isMyJob"
                                    @click="showInviteHeadhunterDialog"
                                    >
                                    <i class="detail-header-btn-icon el-icon-plus"></i>
                                    邀请抢单
                                </span>
                            </h5>
                            <div
                                class="accept-headhunters"
                                v-if="acceptedHeadhuntersCount > 0"
                                >
                                <avatar
                                    class="accepted-headhunters-avatar"
                                    size="md"
                                    :src="item.avatarUrl"
                                    :userId="item.id"
                                    :enableCard="true"
                                    :enableLink="true"
                                    v-for="item in acceptedHeadhunters"
                                    :key="item.id"
                                    :shareData="shareData"
                                ></avatar>
                            </div>
                            <p
                                class="
                                    accept-headhunters
                                    empty
                                    invite-headhunters-btn
                                "
                                v-else-if="jobInfo.isMyJob"
                                @click="showInviteHeadhunterDialog"
                            >
                                您可以邀请您的猎头朋友来抢单推荐，赚取佣金
                            </p>
                            <p
                                class="accept-headhunters empty"
                                v-else-if="isHeadhunter"
                            >
                                想挑战本职位吗，赶快抢单吧！
                            </p>
                            <p class="accept-headhunters empty" v-else>
                                还没有猎头抢单
                            </p>
                        </div>
                    </el-tab-pane>
                    <!-- 推荐 -->
                    <el-tab-pane name="recommend" v-if="approvedStatus != 2">
                        <!-- <span slot="label"> 推荐({{ recommendCount }}) </span> -->
                        <span slot="label"> 推荐({{ jobInfo.recommendationCount }}) </span>
                        <!-- <div class="main-content-item"> -->
                            <job-recommend-info
                                ref="jobRecommendInfo"
                                id="job-recommend-info"
                                :shareData="shareData"
                                :jobInfo="jobInfo"
                                @updateRecommendCount="updateRecommendCount"
                            >
                            </job-recommend-info>
                        <!-- </div> -->
                    </el-tab-pane>
                    <!-- 讲解 -->
                    <el-tab-pane name="jobMedia" v-if="canMediaList && ![3,5].includes(currentJobType) && !['2','4'].includes(approvedStatus)">
                        <span slot="label">
                            讲解({{ jobInfo.mediaNumber || 0 }})
                            <span
                                class="new-video-tip"
                                v-if="!hasNewMediaViewed"
                            ></span>
                        </span>
                        <div class="job-video-list" v-loading="jobMediaLoading">
                            <template v-for="(item, index) in jobMediaList">
                                <job-media-item
                                    :ref="`jobMedia_${item.id}`"
                                    :key="index"
                                    :item="item"
                                    :shareData="shareData"
                                    :canDelete="canMediaDelete"
                                    :index="index"
                                    v-show="item.enableShow"
                                    @delete-media-item="deleteMediaItem"
                                ></job-media-item>
                            </template>
                            <div
                                class="show-more-job-video"
                                @click="getJobMedia(true)"
                                v-if="
                                    !isJobMediaCompleted &&
                                    jobMediaList.length > 0
                                "
                            >
                                加载更多
                                <font-icon
                                    class="get-more-icon"
                                    href="#icon-spread-up-copy"
                                ></font-icon>
                            </div>
                            <div
                                class="empty-media-list"
                                v-if="
                                    isJobMediaCompleted &&
                                    jobMediaList.length === 0
                                "
                            >
                                <span class="empty-img"></span>
                                <p class="empty-data">暂无数据</p>
                            </div>
                        </div>
                    </el-tab-pane>
                    <!-- 评论 -->
                    <el-tab-pane name="jobComments" v-if="!isFromAuthorization && ![3,5].includes(currentJobType) && !['2','4'].includes(approvedStatus) && !jobInfo.isSleeping">
                        <span slot="label"> 评论({{ commentCount }}) </span>
                        <div class="main-content-item" style="margin-top: 20px;">
                            <comments
                                ref="comments"
                                commentType="job"
                                placeholderText="补充职位要点，回复猎头咨询。还可以@他人昵称试试"
                                class="card"
                                :commentObjectInfo="jobInfo"
                                :isEmail="true"
                                :team="true"
                                :prefetch="true"
                                :shareData="shareData"
                                :max-limit="1"
                                @updateCommentCount="updateCommentCount"
                            ></comments>
                        </div>
                    </el-tab-pane>
                    <span
                        class="button-list"
                        v-if="
                            !isFromAuthorization &&
                            jobActiveTabName === 'jobIntro'
                        "
                    >
                        <!-- <button
                            class="job-detail-btn"
                            v-if="
                                !jobInfo.isSleeping &&
                                (jobInfo.isMyJob ||
                                    (isHeadhunter && jobInfo.isMyOrder))
                            "
                            @click="showTransferJobDialog"
                        >
                            <font-icon
                                class="job-detail-btn-icon default"
                                href="#icon-jiaohuan"
                            ></font-icon>
                            <font-icon
                                class="job-detail-btn-icon hover"
                                href="#icon-jiaohuan-copy"
                            ></font-icon>
                            移交
                        </button> -->
                        <!-- <a
                            class="job-detail-btn"
                            :href="`/Headhunting/MyCompany.html#/jobCreate/single?jobId=${jobInfo.id}`"
                            v-if="
                                ((jobInfo.jobType == 2 &&
                                    jobInfo.isPublic == false) ||
                                    jobInfo.acceptedHeadhuntersCount == 0) &&
                                canEdit
                            "
                            target="_blank"
                        > -->
                        <!-- <a
                            class="job-detail-btn"
                            :href="`/Headhunting/#/jobCreate/single?jobId=${jobInfo.id}`"
                            v-if="((jobInfo.jobType == 2 &&jobInfo.isPublic == false) || jobInfo.acceptedHeadhuntersCount == 0) && canEdit"
                            target="_blank"
                        >
                            <i class="job-detail-btn-icon edit-icon el-icon-edit-outline"></i>
                            编辑
                        </a> -->
                        <button
                            class="job-detail-btn"
                            v-if="canEdit"
                            @click="partlyEdit(0)"
                        >
                            <i class="job-detail-btn-icon edit-icon el-icon-edit-outline"></i>
                            {{ isEditJobDescription ? "取消" : "编辑" }}
                        </button>
                    </span>
                </el-tabs>
            </div>

            <div class="main-content-right">
                <!-- <div class="main-content-item">
                    <user-card
                        :avatarUrl="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.avatarUrl : '') : jobInfo.recruiterAvatarUrl"
                        :userId="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.userId : '') : jobInfo.recruiterId"
                        :isOnline="
                            onlineList[jobInfo.recruiterUnionId] || false
                        "
                        :userUnionId="jobInfo.recruiterUnionId"
                        :realName="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.realName : '') : jobInfo.recruiterRealName"
                        :nickName="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.nickname : '') : jobInfo.recruiterNickname"
                        :firmName="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.firmName : '') : jobInfo.recruiterFirmShortName"
                        :title="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.title : '') : jobInfo.recruiterTitle"
                        :shareData="shareData"
                    >
                        <p class="text-grey creat-time">
                            {{ jobInfo.created }} 发布
                        </p>
                        <el-button
                            v-if="!jobInfo.isMyJob"
                            class="contact-recruiter-btn"
                            @click="currentJobType == 3 ? contactRecruiter(jobInfo.recruiter.unionId) : contactRecruiter(jobInfo.recruiterUnionId)"
                            >联系发布者</el-button
                        >
                    </user-card>
                  <template v-for="(projectManager, index) in jobInfo.projectManagers">
                    <user-card
                        :avatarUrl="projectManager.avatarUrl"
                        :userId="projectManager.id"
                        :isOnline="onlineList[projectManager.unionId] || false"
                        :userUnionId="projectManager.unionId"
                        :realName="projectManager.realName"
                        :nickName="projectManager.nickname"
                        :firmName="projectManager.firmShortName"
                        :title="projectManager.title"
                        :isPM="true"
                        v-if="jobInfo.projectManagers.length > 0"
                        :key="index"
                    ></user-card>
                  </template>
                </div> -->
                <div class="content-right-top">
                    <span class="tit">最新动态</span>
                    <div class="header-tabs">
                        <span
                            class="tab-item"
                            :class="feedTab == 0 ? 'activite' : ''"
                            @click="feedActivite(0)"
                            >全部</span
                        >
                        <span
                            class="tab-item"
                            :class="feedTab == 3 ? 'activite' : ''"
                            @click="feedActivite(3)"
                            >拒绝</span
                        >
                        <span
                            class="tab-item"
                            :class="feedTab == 8 ? 'activite' : ''"
                            @click="feedActivite(8)"
                            >面试</span
                        >
                        <span
                            class="tab-item"
                            :class="feedTab == 11 ? 'activite' : ''"
                            @click="feedActivite(11)"
                            >淘汰</span
                        >
                    </div>
                </div>


                <div class="feed-list" v-loading="feedLoading">
                    <ul v-if="lastestFeeds.length > 0">
                        <template v-for="(feed, index) in lastestFeeds">
                        <li
                            class="feed-item"
                            :key="index"
                            v-if="feed"
                        >
                            <avatar
                                class="post-avatar"
                                size="md"
                                :src="feed.posterAvatarUrl"
                                :userId="feed.posterId"
                                :enableCard="true"
                                :enableLink="true"
                                :shareData="shareData"
                            ></avatar>
                            <div class="right">
                                <div class="right-top">
                                    <span class="post-name" v-if="feed.posterRealName == '系统内置用户'">
                                        {{ feed.posterRealName }}
                                    </span>
                                    <span class="post-name" v-else :title="feed.posterRealName+'@'+feed.posterNickName">
                                        {{ feed.posterRealName }}
                                        <span class="text-grey"
                                            >@{{feed.posterNickName}}</span
                                        >
                                    </span>
                                    <span class="feed-time">
                                        {{feed.createdString | dateFilter}}
                                    </span>
                                </div>
                                <div
                                    class="right-bottom"
                                    v-html="getFeedText(feed)"
                                ></div>
                            </div>
                        </li>
                        </template>
                    </ul>
                    <!-- <div class="empty-data" v-else>暂无数据</div> -->

                    <div class="page-empty" v-else>
                        <img src="~@src/assets/images/jobList/empty-bg@2x.png" />
                        <p class="des">暂无数据</p>
                    </div>
                </div>


                <div
                    class="get-more"
                    v-if="hasMoreFeed"
                    @click="getFeeds(true)"
                >
                    加载更多
                    <font-icon
                        class="get-more-icon"
                        href="#icon-spread-up-copy"
                    ></font-icon>
                </div>
                
            </div>
        </div>

        <!-- <div class="lbd-sign"></div> -->

        <!-- 移交职位 -->
        <transfer-job-dialog
            ref="transferJobDialog"
            :isAllMembers="true"
            :isAdminTransfer="true"
            @update-list="refresh"
        ></transfer-job-dialog>
        <!-- 分享给候选人 -->
        <share-to-candidate-dialog
            ref="shareToCandidateDialog"
            :jobInfo="jobInfo"
        ></share-to-candidate-dialog>
        <!-- 邀请抢单 -->
        <invite-headhunter-dialog
            ref="inviteHeadhunterDialog"
            :jobInfo="jobInfo"
            :isHeadhunter="isHeadhunter"
        ></invite-headhunter-dialog>

        <!-- Hr直招联系发布者弹窗 -->
        <el-dialog
            title="联系发布者"
            :visible.sync="dialogVisible"
            v-loading="hrContactLoading"
            width="480px"
            :before-close="handleClose">
            <div class="contact-publisher-content">
                <span>联系电话：{{ mobile }}</span>
                <div class="QR-code">
                    <img :src="wechatQRCodeUrl" alt="">
                </div>
                <span>扫码添加微信</span>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import moment from "moment";
import DetailItemHeader from "../component/detail-item-header.vue";
import TransferJobDialog from "#/component/common/dialog/transferJobDialog.vue";
import ShareToCandidateDialog from "../component/share-to-candidate-dialog.vue";
import InviteHeadhunterDialog from "../component/invite-headhunter-dialog.vue";
import Avatar from "#/component/common/avatar.vue";
import Comments from "#/component/comments/index.vue";
import UserCard from "#/component/common/card/user-card.vue";
import {
    job as jobUrl,
    trackingList as trackingListUrl,
} from "#/js/config/api.json";
import { codeMap as feedStatusMap } from "#/js/config/feedStatus.json";
import emitter from "#/js/mixins/emitter";
import JobMediaItem from "#/component/mediaPlay/media-item.vue";
import JobRecommendInfo from "../component/job-recommend-info.vue";
import MediaService from "#/js/service/mediaService.js";
import resumeMergeService from "#/js/service/resumeMerge.js";
import eventBus from '#/js/util/event-bus.js';

export default {
    name: "jobDetailContent",
    components: {
        DetailItemHeader,
        TransferJobDialog,
        ShareToCandidateDialog,
        InviteHeadhunterDialog,
        Avatar,
        Comments,
        UserCard,
        JobMediaItem,
        JobRecommendInfo,
    },
    props: {
        currentJobType: Number,
        jobInfo: Object,
        shareData: Object,
        onlineList: Object,
        canMediaList: {
            type: Boolean,
            default: false,
        },
        isFromAuthorization: Boolean,
    },
    mixins: [emitter],
    computed: {
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser || false;
        },
        isEmployer() {
            return this.$store.state.user.userInfo.isEmployer || false;
        },
        isHeadhunter() {
            return this.$store.state.user.userInfo.isHeadhunter || false;
        },
        canEdit() {
            return (
                (!this.jobInfo.isFromAuthorization &&
                    this.jobInfo.isMyJob &&
                    (this.jobInfo.status == 1 || this.jobInfo.status == 2)) ||
                false
            );
        },
        token() {
            return this.$store.state.verificationToken.verificationToken;
        },
        approvedStatus() {
            return this.$route.query.approvedStatus;
        },
    },
    watch: {
        "jobInfo.id": function (val) {
            this.getFeeds(false);
            if (this.$refs.comments && this.currentJobType != 3) {
                this.$refs.comments.isShowOperation =
                    this.jobInfo.isMyJob || this.jobInfo.isProjectManagerOfJob;
                this.$nextTick(() => {
                    console.log(this.$refs)
                    console.log(this.$refs.comments)
                    this.$refs.comments?.refresh();
                });
            }
            if(this.currentJobType == 3 || this.currentJobType == 4) {
                this.getAcceptedHeadhunters();
            }
        },
    },
    data() {
        return {
            acceptedHeadhuntersLoading: false,
            acceptedHeadhuntersCount: '',
            acceptedHeadhunters: [],
            dialogVisible: false,
            hrContactLoading: false,
            mobile: '', // hr联系电话
            wechatQRCodeUrl: '', // hr联系二维码
            isEditJobDescription: false,
            isEditJobAnalysis: false,
            descriptionInput: this.jobInfo.description,
            analysisInput: this.jobInfo.analysis,
            jobDescriptionLoading: false,
            jobAnalysisLoading: false,
            descriptionError: false,
            analysisError: false,
            feedTab: 0,
            feedLoading: false,
            hasMoreFeed: false,
            feedStart: 0,
            feedStatusMap: feedStatusMap,
            lastestFeeds: [],
            commentCount: 0,
            recommendCount: 0,
            jobActiveTabName: "jobIntro",
            hasNewMediaViewed: true,
            jobMediaList: [],
            jobMediaNextId: Number.MAX_SAFE_INTEGER,
            jobMediaListTake: 10,
            jobMediaLoading: false,
            isJobMediaCompleted: false,
            nullFileIdList: [],
            canMediaDelete: false,
            currentPath: this.$route.path,
        };
        
    },
    filters: {
        dateFilter(val) {
            return val.slice(0, 10);
        },
        createDateFilter(val) {
            let today = moment().format('YYYY-MM-DD'),
                dateDay = moment(val).format('YYYY-MM-DD');
            if(today === dateDay) {
                return '今天';
            } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                return '昨天';
            } else {
                return dateDay;
            }
        },
    },
    mounted() {
        let jobId = this.$route.params.jobId;
        let rootURL = '/Job/'+jobId
        let recommendationid = this.$route.params.recommendationid;

        if(!recommendationid && this.$route.path.includes('recommendationList')) {
            this.jobActiveTabName = 'recommend';
            this.$nextTick(() => {
                this.$refs.jobRecommendInfo && this.$refs.jobRecommendInfo.toggleState('list');    
            });
        } else if(recommendationid && this.$route.path.includes('recommendation/')) {
            this.jobActiveTabName = 'recommend';
            this.$nextTick(() => {
                this.$refs.jobRecommendInfo && this.$refs.jobRecommendInfo.toggleState('detail');    
            });
        }

        eventBus.$on('canEditJobDescription', data => {
            this.isEditJobDescription = data;
            this.descriptionInput = this.jobInfo.description;
        })
    },
    
    methods: {
        getAcceptedHeadhunters() {
            this.acceptedHeadhuntersLoading = true;
            const _jobId = this.approvedStatus == 4 ? this.jobInfo.jobId : this.jobInfo.id;
            _request({
                url: "/openapi/Job/GetAcceptedHeadhunters/%p".replace(/\%p/ig, _jobId),
                method: 'GET',
                baseURL: "LbdOpenApi",
            }).then((res) => {
                console.log(res);
                this.acceptedHeadhunters = res;
                this.acceptedHeadhuntersCount = res.length;
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.acceptedHeadhuntersLoading = false;
            });
        },

        handleClose() {
            this.dialogVisible = false;
        },
        showTransferJobDialog() {
            let transferJobs = [];
            transferJobs.push(this.jobInfo);
            this.$refs.transferJobDialog.initDialog(transferJobs);
        },
        partlyEdit(type) {
            if (type == 0) {
                if(this.jobInfo.status != 1) {
                    return shortTips(`当前职位状态为非招聘中，不能编辑!`);
                }
                this.isEditJobDescription = !this.isEditJobDescription;
                this.descriptionInput = this.jobInfo.description;
            } else {
                this.isEditJobAnalysis = !this.isEditJobAnalysis;
                this.analysisInput = this.jobInfo.analysis;
            }
        },
        jobDescriptionInput() {
            if (!this.descriptionInput) {
                this.descriptionError = true;
            } else {
                this.descriptionError = false;
            }
        },
        jobAnalysisInput() {
            if (!this.analysisInput) {
                this.analysisError = true;
            } else {
                this.analysisError = false;
            }
        },
        handleEditJobDescription() {
            if (
                this.descriptionError ||
                this.descriptionInput == this.jobInfo.description
            ) {
                this.isEditJobDescription = false;
            } else {
                this.jobDescriptionLoading = true;
                let params = {};
                const _jobId = this.approvedStatus == 4 ? this.jobInfo.jobId : this.jobInfo.id;
                params.Description = this.descriptionInput;
                params.jobId = _jobId;
                params.__RequestVerificationToken = this.token;
                _request({
                    url: jobUrl.update_description,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: params,
                })
                    .then((res) => {
                        this.jobDescriptionLoading = true;
                        if(this.jobInfo.jobType == 4) {
                            shortTips('您编辑的职位已提交，请等待工作人员审批');
                            setTimeout(() => {
                            //   this.$router.push({
                            //       path: '/myOrders/a2aAuditJob',
                            //       query: {
                            //         refresh: true,
                            //       }
                            //     });
                                location.href = '/#/myOrders/a2aAuditJob?refresh=true';
                            }, 2000);
                        } else {
                            shortTips("修改成功");
                        }
                        this.isEditJobDescription = false;
                        this.refresh();
                    })
                    .catch((err) => {
                        this.jobDescriptionLoading = false;
                    });
            }
        },
        handleEditJobAnalysis() {
            if (
                this.analysisError ||
                this.analysisInput == this.jobInfo.analysis
            ) {
                this.isEditJobAnalysis = false;
            } else {
                this.jobAnalysisLoading = true;
                let params = {};
                params.Analysis = this.analysisInput;
                params.jobId = this.jobInfo.id;
                params.__RequestVerificationToken = this.token;
                _request({
                    url: jobUrl.update_analysis,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: params,
                })
                    .then((res) => {
                        this.jobAnalysisLoading = true;
                        shortTips("修改成功");
                        this.isEditJobAnalysis = false;
                        this.refresh();
                    })
                    .catch((err) => {
                        this.jobAnalysisLoading = false;
                    });
            }
        },
        cancelEditJobDetail(type) {
            if (type == 0) {
                this.isEditJobDescription = false;
                this.descriptionInput = this.jobInfo.description;
            } else {
                this.isEditJobAnalysis = false;
                this.analysisInput = this.jobInfo.analysis;
            }
        },
        showShareToCandidateDialog() {
            this.$refs.shareToCandidateDialog.show();
        },
        showInviteHeadhunterDialog() {
            this.$refs.inviteHeadhunterDialog.headhunterTab = this.jobInfo
                .isCooperativeJob
                ? "4"
                : "1";
            this.$refs.inviteHeadhunterDialog.show();
        },
        feedActivite(index) {
            this.feedTab = index;
            this.getFeeds(false);
        },
        getFeedText(feed) {
            let recommendationDetailUrl = "";
            if (feed.extensionProperties.redirectId) {
                // recommendationDetailUrl = `/Headhunting/MyCompany.html#/candidateDetail/${feed.extensionProperties.redirectId}/recommendation/${feed.extensionProperties.recommendationId}`;
                recommendationDetailUrl = `/Headhunting/MyCompany.html#/candidateDetail/${feed.extensionProperties.redirectId}/recommendation/${feed.extensionProperties.recommendationId}`;
            } else {
                recommendationDetailUrl = feed.extensionProperties
                    .recommendationDetailUrl
                    ? feed.extensionProperties.recommendationDetailUrl
                    // : `/Headhunting/MyCompany.html#/candidateDetail/${feed.extensionProperties.candidateId}/recommendation/${feed.extensionProperties.recommendationId}`;
                    : feed.extensionProperties.recommendationId ? (`/Headhunting/MyCompany.html#/candidateDetail/${feed.extensionProperties.candidateId}/recommendation/${feed.extensionProperties.recommendationId}`) : `/Headhunting/MyCompany.html#/candidateDetail/${feed.extensionProperties.candidateId}/recommendationList`;
            }
            let pString = `<a href='${recommendationDetailUrl}' class='text-primary' target='_blank'>${feed.extensionProperties.candidateName}</a>`,
                mString =
                    feed.extensionProperties.result == "未反馈"
                        ? feed.extensionProperties.interviewType
                        : `${feed.extensionProperties.interviewType}（${feed.extensionProperties.result}）`,
                uString = feed.extensionProperties.to,
                cString = `<span class='text-primary'>${feed.extensionProperties.unconfirmedUserName}</span>`,
                result = "";

            // 项目动态
            let programDetailUrl = "";
            if (feed.extensionProperties.projectId) {
                const projectName =
                    feed.extensionProperties.projectName.replace("$#$", "-");
                // programDetailUrl =
                //     feed.extensionProperties.projectDetailUrl ||
                //     `/Headhunting/MyCompany.html#/ProjectDetail/${feed.extensionProperties.projectId}`;
                programDetailUrl =
                    feed.extensionProperties.projectDetailUrl ||
                    `/Headhunting/MyCompany.html#/ProjectDetail/${feed.extensionProperties.projectId}`;
                // pString = `<a href='${programDetailUrl}' class='text-primary' target='_blank'>${feed.extensionProperties.projectName}</a>`;
                pString = `<a href='${programDetailUrl}' class='text-primary' target='_blank'>${projectName}</a>`;
            }
            result =
                this.feedStatusMap[feed.type] &&
                this.feedStatusMap[feed.type].job_desc
                    .replace(/\%p/gi, pString)
                    .replace(/\%m/gi, mString)
                    .replace(/\%u/gi, uString)
                    .replace(/\%c/gi, cString);

            // Offer中6子状态新增状态
            const offerStatusMap = {
                41: 'S1全部面试通过', 
                29: "S2提交流水等材料",
                30: "S3客户审批offer",
                31: "S4沟通offer细节",
                32: "S5等待接受offer",
                33: "S6双方确认offer",
            };
            if ([41, 29, 30, 31, 32, 33].includes(feed.type)) {
                result = `将候选人 ${pString} 推进到Offering阶段，当前状态为 ${
                    offerStatusMap[feed.type]
                }`;
            }

            if([35, 37].includes(feed.type)) {
                const actionsMap = ['创建', '重新申请', '编辑', '填写', '填写', '驳回', '取消', '移交', '确认', '作废'];
                const paymentMethodMap = ['全款', '首款', '尾款'];
                const applicationTypeMap = ['', '负数'];
                const ticketsType = ['开票申请', '开票申请', '开票申请', '开票信息', '回款信息', '开票申请', '开票申请', '开票申请', '开票申请', '开票申请'];

                result = ` <span>${actionsMap[+feed.extensionProperties.operationType]}了${paymentMethodMap[+feed.extensionProperties.paymentMethodType]}${applicationTypeMap[+feed.extensionProperties.invoiceApplicationType]}${ticketsType[+feed.extensionProperties.operationType]}</span>
                ,当前状态为${feed.type == 35 ? (feed.extensionProperties.recommendStatus == '26' ? '已回款': '开票中'): '离职'}
                `;
            }
            return result;
        },
        getFeeds(isGetMore) {
            if (isGetMore) {
                this.feedStart += 5;
            } else {
                this.feedStart = 0;
            }
            this.feedLoading = true;
            this.hasMoreFeed = false;
            _request({
                url: jobUrl.get_feed.replace(/\%p/gi, this.jobInfo.id),
                method: "GET",
                baseURL: "LbdOpenApi",
                data: {
                    start: this.feedStart,
                    take: 5,
                    jobId :this.jobInfo.id,
                    Type: this.feedTab == 0 ? "" : this.feedTab,
                    _: new Date().getTime(),
                },
            })
                .then((res) => {
                    if (
                        res.length < 5 ||
                        res[4].type == 15
                    ) {
                        this.hasMoreFeed = false;
                    } else {
                        this.hasMoreFeed = true;
                    }
                    let list = res;
                    let ids = [];
                    list.forEach((item) => {
                        if (
                            item.extensionProperties &&
                            item.extensionProperties.candidateId
                        ) {
                            ids.push(item.extensionProperties.candidateId);
                        }
                    });
                    if (ids.length > 0) {
                        resumeMergeService
                            .getCandidateRedirectIds(ids)
                            .then((res) => {
                                if (res) {
                                    list.forEach((item) => {
                                        if (
                                            item.extensionProperties &&
                                            item.extensionProperties
                                                .candidateId &&
                                            res[
                                                item.extensionProperties
                                                    .candidateId
                                            ]
                                        ) {
                                            item.extensionProperties.redirectId =
                                                res[
                                                    item.extensionProperties.candidateId
                                                ];
                                        }
                                    });
                                }
                                this.feedLoading = false;
                                if (!isGetMore) {
                                    this.lastestFeeds = [];
                                }
                                this.lastestFeeds =
                                    this.lastestFeeds.concat(list);
                            })
                            .catch((err) => {
                                this.feedLoading = false;
                                if (!isGetMore) {
                                    this.lastestFeeds = [];
                                }
                                this.lastestFeeds = this.lastestFeeds.concat(list);
                            });
                    } else {
                        this.feedLoading = false;
                        if (!isGetMore) {
                            this.lastestFeeds = [];
                        }
                        this.lastestFeeds = this.lastestFeeds.concat(list);
                    }
                })
                .catch((err) => {
                    this.feedLoading = false;
                });
                console.log("shareData");
                console.log(this.shareData);
                console.log('此时的this.lastestFeeds');
                console.log(this.lastestFeeds);
        },
        handleAddTrackingList(item) {
            let _this = this;
            if (window.event.type !== "click" || !window.event.target) {
                return;
            }
            if (window.event.target.classList[0] !== "el-button") {
                return;
            }
            if (window.lbdTrack && window._request) {
                _request({
                    url: trackingListUrl.batch_trackinglist.replace(
                        /\%p/gi,
                        11
                    ),
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: {
                        resumeIds: item.resumeId,
                    },
                })
                    .then((res) => {
                        if (res.successCount) {
                            _this.getRecomendCandidate(false);
                            lbdTrack.track("addTrackingList", {
                                candidateId: item.candidateId,
                                resumeId: item.resumeId,
                                from: "jobDetail",
                            });
                            shortTips("已成功加入trackinglist");
                        }
                        if (res.existCount) {
                            item.isTracking = true;
                            shortTips("该人才已存在trackinglist");
                        }
                        if (res.failCount) {
                            shortTips("加入失败，请稍后尝试！");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {});
            }
        },
        handleViewTrackingList(item) {
            if (localStorage) {
                localStorage.setItem(
                    "trackingList_search_name",
                    item.candidateName
                );
                localStorage.setItem(
                    "trackingList_search_company",
                    item.company
                );
                localStorage.setItem("trackingList_search_title", item.title);
                localStorage.setItem("trackingList_search_mobile", item.mobile);
            }
            return window.open(`/Headhunting/#/trackingList`);
        },
        updateCommentCount(count) {
            this.commentCount = count;
        },
        updateRecommendCount(count) {
            this.recommendCount = count;
        },
        refresh() {
            this.$emit("refresh");
        },
        contactPublisher(userId) {
            console.log(userId)
            this.dialogVisible = true;
            this.hrContactLoading = true;
            _request({
                url: '/openapi/User/%p/GetWechatQRCodeInfo'.replace(/%p/g, userId),
                method: "GET",
                baseURL: "LbdOpenApi",
            }).then((res) => {
               console.log(res);
               this.mobile = res.mobile;
               this.wechatQRCodeUrl = res.wechatQRCodeUrl;
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
               this.hrContactLoading = false;
            });
        },
        contactRecruiter(id) {
            if (window.lbdTrack) {
                lbdTrack.track("ContactRecruiterClick", {});
            } else {
                _tracker.track("ContactRecruiterClick");
            }
            setTimeout(() => {
                let jobInfo = this.jobInfo,
                    shareData = Object.assign(
                        {
                            type: "jobShare",
                            unionId: id,
                        },
                        this.shareData
                    );
                this.dispatch("App", "showInstantMessage", {
                    isShare: true,
                    json: shareData,
                });
            }, 100);
        },
        handleJobTabClick(tab) {
            if (tab.name === "jobMedia") {
                if (!this.hasNewMediaViewed) {
                    this.hasNewMediaViewed = true;
                }
                if (!this.jobMediaList.length) {
                    this.getJobMedia();
                }
            } else if(tab.name === "recommend") {
                this.routernavigate();
            } else {
                let jobId = this.$route.params.jobId;
                let rootURL = '#/Job/' + jobId;
                location.href = rootURL;
            }
        },
        routernavigate(){
            let jobId = this.$route.params.jobId;
            let rootURL = '#/Job/' + jobId + '/recommendationList';
            location.href = rootURL;
            this.$nextTick(() => {
                // console.log(this.$refs);
                this.$refs.jobRecommendInfo && this.$refs.jobRecommendInfo.toggleState('list');    
            });
        },
        getJobMedia(isMore) {
            this.jobMediaLoading = true;
            let params = {
                count: this.jobMediaListTake,
                flipOverType: 1,
                jobId: this.jobInfo.id,
                nextMappingId: this.jobMediaNextId,
            };
            let nullFileIdList = [];
            MediaService.getJobMadiaList(params)
                .then((res) => {
                    if (res.mediaList && res.mediaList.length) {
                        res.mediaList.forEach((item) => {
                            item.enableShow = true;
                        });
                        if (isMore) {
                            this.jobMediaList = this.jobMediaList.concat(
                                res.mediaList
                            );
                        } else {
                            this.jobMediaList = res.mediaList;
                        }
                        this.jobMediaNextId = res.nextAfterId;
                        this.isJobMediaCompleted = res.isAfterCompleted;
                        this.$nextTick(() => {
                            this.jobMediaList.forEach((item) => {
                                if (item.fileId) {
                                    this.$refs[
                                        `jobMedia_${item.id}`
                                    ][0].getPlayer();
                                } else {
                                    nullFileIdList.push(item.id);
                                }
                            });
                            if (nullFileIdList.length) {
                                this.nullFileIdList = nullFileIdList;
                                this.getMediaFileId();
                            }
                        });
                    } else {
                        this.isJobMediaCompleted = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.jobMediaLoading = false;
                });
        },
        getMediaFileId() {
            let nullFileIdList = [];
            let params = {};
            params.mappingIds = this.nullFileIdList;
            MediaService.getJobMappingFileId(params)
                .then((res) => {
                    res.forEach((item) => {
                        if (item.fileId) {
                            this.jobMediaList.some((media, index) => {
                                if (media.id === item.id) {
                                    this.$set(this.jobMediaList, index, item);
                                    this.$nextTick(() => {
                                        this.$refs[
                                            `jobMedia_${media.id}`
                                        ][0].getPlayer();
                                    });
                                }
                            });
                        } else {
                            nullFileIdList.push(item.id);
                        }
                    });
                    this.nullFileIdList = nullFileIdList;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    if (this.nullFileIdList.length) {
                        setTimeout(() => {
                            this.getMediaFileId();
                        }, 5000);
                    }
                });
        },
        deleteMediaItem(index) {
            this.jobMediaList[index].enableShow = false;
            this.jobInfo.mediaNumber--;
        },
    },
};
</script>

<style lang="scss" scope>
// @media screen and (max-width: 1400px) {
//     .main-content-left {
//         width: calc(100% - 370px) !important;
//     }
//     .main-content-right{
//         width: 350px !important;
//         .post-name {
//             .text-grey {
//                 width: 140px !important;
//             }
//         }
//     }
// }
// @media screen and (min-width: 1400px) and (max-width: 1680px) {
//     .main-content-left {
//         width: calc(100% - 430px) !important;
//     }
//     .main-content-right{
//         width: 410px !important;
//         .post-name {
//             .text-grey {
//                 width: 190px !important;
//             }
//         }
//     }
// }
.job-detail-content {
    // width: 1200px;
    // margin: 20px auto 0;
    width: 1200px;
    margin: 10px auto 0;
    /deep/ .el-dialog__body {
        padding: 40px 20px 42px;
    }
    .contact-publisher-content {
        text-align: center;
        span {
            font-size: 16px;
            color: #666666;
        }
        .QR-code {
            width: 120px;
            height: 120px;
            // background-color: #36b495;
            margin: 30px auto 14px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .text-error {
        color: #ff493c;
    }
    .contact-hr {
        background: #fcf8e2;
        border: 1px solid #fbecca;
        padding: 0 13px;
        line-height: 41px;
        margin-bottom: 30px;
        .text-operate-btn {
            margin-left: 8px;
        }
    }
    .text-grey {
        color: #b9b9b9;
    }
    .text-primary {
        color: $primary;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
    }
    .font-bold {
        font-weight: bold;
    }
    .main-content {
        display: flex;
        justify-content: space-between;
        // .main-content-item {
        //     margin-bottom: 20px;
        //     position: relative;
        //     .user-card {
        //         background-color: #ffffff;
        //         min-height: 102px;
        //         padding: 20px 20px;
        //         border-bottom: 1px solid #EEEEEE;
        //     }
        // }
        &-left {
            // width: 840px;-
            // width: 70%;
            flex: 1;
            max-width: calc(100% - 400px);
            
            // background-color: #ffffff;
            // border-radius: 8px;
            .infos-card{
                // background: linear-gradient(270deg, #FCFCFC 0%, #F7F8F8 100%);
                background: white;
                border-radius: 8px;
                padding: 21px 23px;
                display: flex;
                // margin-top: 30px;
                align-items: center;
                margin-bottom: 10px;
            
                .user-pic{
                    width: 58px;
                    height: 58px;
                    margin-right: 16px;
                    // border: 1px solid rgba(168,168,168,0.44);
                    overflow: hidden;
                    .avatar-sm {
                        width: 58px;
                        height: 58px;
                        border: 1px solid rgba(168,168,168,0.44);
                        border-radius: 50%;
                    }
                }
                .user-info{
                    flex: 1;
                    >p{
                        margin: 0;
                    }
                    .name{
                        font-size: 16px;
                        font-weight: 500;
                        color: #333;
                        line-height: 17px;
                        .nick{
                            color: #333;
                            font-size: 12px;
                        }
                    }
                    .firm-info{
                        color: #666;
                    }
                    .time{
                        font-size: 11px;
                        font-weight: 500;
                        color: #999999;
                        line-height: 16px;
                    }
                }
                .infos-right{
                    width: 110px;
                    .contact-btn{
                        width: 106px;
                        height: 34px;
                        border-radius: 5px;
                        border: 1px solid #38BC9D;
                        font-size: 14px;
                        font-weight: 500;
                        color: #38BC9D;
                        line-height: 20px;
                        &:hover{
                            background: #fafafa;
                        }
                    }
                }
            }
            .main-content-item {
                padding-left: 22px;
            }

            .job-detail-btn {
                font-size: 14px;
                color: #b9b9b9;
                margin-left: 25px;
                outline: none;
                margin-top: 16px;
                &-icon {
                    width: 18px;
                    height: 18px;
                }
                .el-icon-edit-outline {
                    font-size: 16px;
                    width: 16px;
                    height: auto;
                }
                .hover {
                    display: none;
                }
                &:hover {
                    color: $primary;
                    .default {
                        display: none;
                    }
                    .hover {
                        display: inline-block;
                    }
                }
            }
            .firm-label-list {
                // padding: 0 28px 0 0;
                margin-bottom: 16px;
                .firm-label-item {
                    display: inline-block;
                    margin-left: 20px;
                    padding: 0 20px;
                    border: 1px solid #38bc9d;
                    color: $primary;
                    line-height: 36px;
                    height: 36px;
                    &:nth-of-type(1) {
                        margin-left: 0;
                    }
                }
            }
            .job-description-show {
                // padding: 0 28px;
                line-height: 24px;
                margin-bottom: 14px;
                white-space: pre-wrap;
                font-family: inherit;
            }
            .job-description-edit {
                margin: 20px 0;
                .error-input {
                    .el-textarea__inner {
                        border-color: #ff493c;
                    }
                }
                .edit-btn {
                    margin: 14px 8px 0 0;
                    padding: 0 20px;
                    height: 28px;
                    width: auto;
                    line-height: 28px;
                    border-radius: 3px;
                    &.edit-btn-confirm {
                        background: $primary;
                        color: #fff;
                        &:hover {
                            background-color: #36b495;
                        }
                    }
                }
            }
            .job-share-board {
                background-color: #f1faf7;
                padding: 20px;
                // margin: 0 28px;
                &-msg {
                    margin: 0;
                }
                &-btn {
                    margin-top: 14px;
                    padding: 0 20px;
                    height: 28px;
                    width: auto;
                    line-height: 28px;
                    border-radius: 3px;
                    background: $primary;
                    color: #fff;
                    &:hover {
                        background-color: #36b495;
                    }
                    .email-icon {
                        width: 22px;
                        height: 22px;
                        margin-top: -3px;
                    }
                }
            }
            .detail-header-btn {
                font-size: 16px;
                color: $primary;
                i {
                    font-weight: bold;
                }
            }
            .accept-headhunters {
                margin: 20px 0;
                // padding: 0 28px;
                cursor: default;
                &.empty {
                    line-height: 24px;
                    margin: 0;
                }
                &.invite-headhunters-btn {
                    cursor: pointer;
                    &:hover {
                        color: $primary;
                    }
                }
                .accepted-headhunters-avatar {
                    display: inline-block;
                    margin-right: 10px;
                }
            }
            .job-detail-tabs.el-tabs {
                background: white;
                border-radius: 10px;
                .el-tabs__nav-wrap{
                    &:after{
                        height: 1px;
                    }
                }
                .el-tabs__nav-scroll {
                    padding: 0 28px;
                    &::after {
                        height: 1px;
                    }
                }
                .el-tabs__item {
                    font-size: 16px;
                    color: #333;
                    position: relative;
                    font-weight: bold;
                    height: 46px;
                    line-height: 46px;
                    &.is-active {
                        color: $primary;
                    }
                    &:hover {
                        color: $primary;
                    }
                    .new-video-tip {
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #d0021b;
                        position: absolute;
                        top: 8px;
                        right: 8px;
                    }
                }
                .el-tabs__header {
                    margin-bottom: 2px;
                }
                .el-tabs__content {
                    position: relative;
                    overflow: unset;
                    .button-list {
                        position: absolute;
                        // right: 0;
                        right: 28px;
                        top: -52px;
                    }
                    .el-tab-pane{
                        padding-bottom: 30px;
                    }
                }
                .job-intro-title {
                    font-size: 16px;
                    color: #333;
                    line-height: 48px;
                    height: 48px;
                    font-weight: bold;
                    margin-bottom: 0;
                    .text-primary {
                        color: $primary;
                        // white-space:normal;
                        // word-break: break-all;
                        // word-wrap: break-word;
                    }
                    .text-operate-btn {
                        float: right;
                        margin-right: 28px;
                    }
                    .invite-grab-btn{
                        display: inline-block;
                        width: 90px;
                        height: 34px;
                        border-radius: 5px;
                        border: 1px solid #38BC9D;
                        font-size: 14px;
                        font-weight: 500;
                        color: #38BC9D;
                        line-height: 32px;
                        text-align: center;
                        cursor: pointer;
                    }
                    // &::before {
                    //     content: "";
                    //     display: inline-block;
                    //     height: 22px;
                    //     width: 8px;
                    //     background-color: #38bc9d;
                    //     margin-right: 5px;
                    //     margin-top: 13px;
                    //     vertical-align: top;
                    // }
                }
                .show-more-job-video {
                    width: 100%;
                    height: 28px;
                    margin-bottom: 25px;
                    line-height: 28px;
                    font-size: 14px;
                    text-align: center;
                    border: 1px solid #ccc;
                    color: #ccc;
                    .get-more-icon {
                        width: 16px;
                        height: 16px;
                    }
                }
                .empty-media-list {
                    height: 300px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .empty-img {
                        display: inline-block;
                        background: url("~@src/assets/images/home/icon_no_data.svg")
                            no-repeat;
                        width: 240px;
                        height: 228px;
                    }
                    .empty-data {
                        font-size: 16px;
                        line-height: 21px;
                        margin-bottom: 0px;
                    }
                }
            }
        }
        &-right {
            margin-left: 10px;
            width: 390px;
            background-color: #ffffff;
            border-radius: 10px;
            padding: 19px 17px;
            .content-right-top{
                line-height: 22px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .tit{
                    font-size: 16px;
                    font-weight: 600;
                    color: #333;
                    line-height: 22px;
                }
                .header-tabs {
                    display: inline-flex;
                    justify-content: space-between;
                    vertical-align: middle;
                    .tab-item {
                        line-height: 16px;
                        margin-left: 16px;
                        padding-bottom: 4px;
                        color: #666;
                        font-size: 14px;
                        cursor: pointer;
                        &:nth-of-type(1) {
                            margin-left: 0;
                        }
                        &.activite {
                            cursor: default;
                            color: $primary;
                            // border-bottom: 2px solid $primary;
                        }
                    }
                }
            }

            // .creat-time {
            //     font-size: 12px;
            // }
            // .contact-recruiter-btn {
            //     position: absolute;
            //     top: 45px;
            //     align-self: flex-end;
            //     width: 80px;
            //     height: 24px;
            //     line-height: 24px;
            //     min-width: unset;
            //     padding: 0;
            //     background-color: #fff;
            //     border: 1px solid $primary;
            //     border-radius: 3px;
            //     color: $primary;
            //     font-size: 12px;
            //     &:hover,
            //     &:focus,
            //     &:active {
            //         background-color: $primary;
            //         color: #fff;
            //     }
            // }
            
            .feed-list {
                min-height: 100px;
                // padding: 0 28px;
                .feed-item {
                    min-height: 52px;
                    margin-top: 20px;
                    display: flex;
                    &:nth-of-type(1) {
                        margin-top: 15px;
                    }
                    // .user-img {
                    //     width: 38px;
                    //     height: 38px;
                    //     border-radius: 50%;
                    // }
                    .post-avatar {
                        margin-right: 10px;
                        width: 38px;
                        height: 38px;
                        margin-right: 9px;
                    }
                    .right{
                        flex: 1;
                        .right-top {
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 4px;
                            .post-name {
                                font-size: 14px;
                                font-weight: 500;
                                color: #333333;
                                line-height: 20px;
                                max-width: 230px;
                                display: inline-block;
                                white-space: nowrap;

                                overflow: hidden;
                                text-overflow: ellipsis;
                                .text-grey {
                                    // display: inline-block;
                                    // vertical-align: middle;
                                    // width: 230px;

                                    // overflow: hidden;
                                    // text-overflow: ellipsis;
                                    font-size: 12px;
                                    color: #b9b9b9;
                                }
                            }
                            .feed-time {
                                font-size: 12px;
                                white-space: nowrap;
                                color: #b9b9b9;
                            }
                        }
                        .right-bottom{
                            color: #999;
                            font-size: 14px;
                        }
                    }
                    
                }
                .page-empty{
                    width: 100%;
                    height: 383px;
                    background: white;
                    border-radius: 10px;
                    text-align: center;
                    >img{
                        width: 158px;
                        height: 178px;
                        margin: 73px auto 0;
                    }
                    .des{
                        margin: 24px auto 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: #999999;
                        line-height: 20px;
                    }
                }
            }
            .candidate-list {
                margin-bottom: 0;
                min-height: 100px;
                background-color: #fff;
                border: 1px solid #ddd;
                padding: 0 20px;
                ul {
                    margin: 0;
                }
                .candidate-item {
                    color: #666;
                    padding: 20px 0;
                    border-bottom: 1px solid #ddd;
                    &:nth-of-type(6) {
                        border-bottom: none;
                    }
                    p {
                        margin-bottom: 10px;
                    }
                    .name {
                        color: #444;
                        font-size: 16px;
                        font-weight: bold;
                        &:hover {
                            color: $primary;
                        }
                    }
                    .el-button {
                        padding: 7px 13px;
                    }
                }
                .get-more {
                    border: none;
                    margin: 0;
                }
                .empty-data {
                    border: none;
                }
            }
            .get-more {
                text-align: center;
                height: 28px;
                line-height: 28px;
                // border: 1px solid #ccc;
                font-size: 14px;
                color: #666;
                cursor: pointer;
                // margin-top: 20px;
                margin: 20px 28px 0;
                .get-more-icon {
                    width: 16px;
                    height: 16px;
                }
            }
            .empty-data {
                height: 100px;
                border: 1px solid #ddd;
                color: #666;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .lbd-sign {
        padding-top: 20px;
        &:after {
            content: '© 2023 liebide.com';
            margin-left: 50%;
            text-align: center;
            font-size: 14px;
            color: #999;
        }
    }
}
</style>
<style lang="scss">
.coment-wrap {
    .row-overflow {
        margin-left: 0;
        margin-right: 0;
    }
    .form-horizontal {
        padding: 0 15px;
        .row-overflow {
            display: flex;
            justify-content: space-between;
            &:before,
            &::after {
                display: none;
            }
        }
    }
}
</style>